/******************************************************************************
 * Content
 ******************************************************************************/

 body {
   padding-bottom: 70px;
   color: var(--global-text-color);
   background-color: var(--global-bg-color);
 }

 body.fixed-top-nav {
   // Add some padding for the nav-bar.
   padding-top: 56px;
 }

 body.sticky-bottom-footer {
   // Remove padding below footer.
   padding-bottom: 0;
 }

.container {
  max-width: $max-content-width;
}

// Bio

.bio {
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;

    .about {
      width: 60%;
    }
  }
}

// Profile
.profile {
  img {
    width: 50%;
    margin: 0 auto 3rem auto;
    display: block;
    // -webkit-box-shadow: none !important;
    // -moz-box-shadow: none !important;
    // box-shadow: none !important;
  }
}

// TODO: redefine content layout.


/******************************************************************************
 * Publications
 ******************************************************************************/

// TODO: redefine publications layout.

abbr {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}


/*****************************************************************************
* Projects
*****************************************************************************/

// TODO: redefine projects layout.
